















import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { PersonAccountEntityModel } from '@/entity-model/person-account-entity';
import { UserPwdModel, UserSetBaseInfoModel } from '@/entity-model/user-entity';
import UserService, { UserPwdService } from '@/service/user-v2';
import { ViewModeType } from '@/model/enum';
import UserPwdComponent from '@/pages/dashboard/user/user/user-pwd.vue';

@Component({
    components: {
        'user-pwd-dialog': UserPwdComponent
    }
}
)
export default class PersonSecurityComponent extends BaseComponent {
    private PersonAccountEntityModel: PersonAccountEntityModel = new PersonAccountEntityModel();
    private userIfo = {};

    mounted() {
        this.PersonAccountEntityModel.password = '********';
        new UserService().getUserInfo().then(res => {
            this.userIfo = res;
        }).catch(err => {
            console.log(err);
        });
    }
    newPasswordClick() {
        const userPwdModel = new UserPwdModel();
        userPwdModel.id = this.userIfo['id'];
        (this.$refs.userPwdFormDialog as any).dialogOpen(userPwdModel, new UserPwdService(), ViewModeType.UPDATE);
        (this.$refs.userPwdFormDialog as any).dialogTitle = '重置密码';
    }
    formDialogOK() {
        // relogin
    }
}
