





































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import PersonInfoComponent from './person-info.vue';
import PersonSecurityComponent from './person-security.vue';

const PERSON_MENU = [
    {
        title: '基本资料',
        type: 1,
        url: 'personInfo'
    },
    // {
    //     title: '修改头像',
    //     type: '2',
    //     url: 'editAvatar'
    // },
    {
        title: '账号安全',
        type: 3,
        url: 'accountSecurity'
    }
];
@Component({
    components: {
        'person-info-component': PersonInfoComponent,
        'person-security-component': PersonSecurityComponent
    }
})
export default class PersonIndexComponent extends BaseComponent {
    currentMenuType: number = PERSON_MENU[0].type;
    get PersonMenu() {
        return PERSON_MENU;
    }
}

