












import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { UserSetBaseInfoModel } from '@/entity-model/user-entity';

import FormComponent from '@/mixins/form-component';
import { ViewModeType } from '@/model/enum';
import UserService from '@/service/user-v2';

@Component
export default class PersonInfoComponent extends BaseComponent {
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<UserSetBaseInfoModel>;
    // private UserSetBaseInfoModel: UserSetBaseInfoModel = new UserSetBaseInfoModel();

    mounted() {
        new UserService().getUserInfo().then(res => {
            let userInfo = Object.assign(new UserSetBaseInfoModel(), res);
            this.jtlForm.initForm(userInfo, ViewModeType.UPDATE);
        }).catch(err => {
            console.log(err);
        });
    }
    async saveClick() {
        if (!await this.jtlForm.validate()) {
            this.showMessageError('您提交的数据有问题');
            throw new Error('Form error');
        } else {
            new UserService().updateUserInfo(this.jtlForm.formModel).then(res => {
                this.showMessage('保存成功！');
            }).catch(err => {
                throw err;
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        }
    }
}
