

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { UserPwdModel } from '@/entity-model/user-entity';

@Component
export default class UserPwdComponent extends FormDialogComponent<UserPwdModel> {

}
